<script>
  import { getContext, onMount } from 'svelte';

  const { loadImage } = getContext('lazy-image-loader');

  export let img;
  export let opacity = 1;
  export let position = 'center';
  export let size = 'cover';
  export let webp = false;

  let element;
  let loaded = false;
  let finalOpacity = 0;

  onMount(async () => {
    await loadImage(element);
    finalOpacity = opacity;
  });
</script>

<style>
  div {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background-repeat: no-repeat;
    background-size: cover;
    transition: opacity var(--tmedium) var(--ease);
  }
</style>

<div
  bind:this={element}
  class="bg-img"
  class:loaded={loaded}
  data-img={img}
  data-webp={webp}
  style="background-position: {position}; background-size: {size}; opacity: {finalOpacity};"
/>
