<script>
  import { getContext } from 'svelte';

  import Background from './Background.svelte';
  import Iframe from './Iframe.svelte';
  import LinkList from './LinkList.svelte';
  import SoftwareIcon from './SoftwareIcon.svelte';

  export let software = [];

  const { open: openModal } = getContext('simple-modal');
  const ie11 = !!window.MSInputMethodContext && !!document.documentMode;
  const sixteenToNine = .5625;
  const fourToThree = .75;

  const showYoutube = (talk) => () => {
    const t = talk.startTime ? `&t=${talk.startTime}` : '';
    openModal(
      Iframe,
      {
        src: `https://www.youtube.com/embed/${talk.youtube}?showinfo=0&rel=0${t}`,
        aspectRatio: toAspectRatio(talk.aspectRatio)
      },
      { styleWindow: talk.darkMode ? { background: 'black' } : {} }
    );
  };

  const toAspectRatio = (aspectRatio) => {
    switch (aspectRatio) {
      case 'sixteenToNine':
        return sixteenToNine;

      case 'fourToThree':
        return fourToThree;

      default:
        return aspectRatio;
    }
  };
</script>

<style>
  ol {
    margin: 0;
    padding: 0;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25em;
    margin: 0.5em 0 0.25em 0;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }

  .smaller {
    font-size: 0.9rem;
  }

  .software-tools > li {
    list-style: none;
    margin-top: 2rem;
  }

  .software-tools > li:first-child {
    margin-top: 0;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @supports (display: grid) {
    .wrapper {
      display: grid;
      grid-column-gap: 1rem;
      grid-row-gap: 0.5rem;
      grid-template-columns: auto;
    }
  }

  .software-tools.ie11 .thumbnail {
    display: block;
    width: 6rem;
    margin-right: 1rem;
  }

  .software-tools.ie11 .body {
    width: calc(100% - 7rem);
  }

  .software-tools > li:before {
    display: block;
    content: " ";
    margin-top: -4rem;
    height: 4rem;
    visibility: hidden;
  }

  .thumbnail {
    position: relative;
    width: 100%;
    background: white;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .thumbnail:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 66%;
  }

  .body {
    position: relative;
  }

  .text a {
    margin: 0 -0.25rem;
    padding: 0 0.25rem;
    border-radius: 1em;
    text-decoration: underline solid var(--color-033);
    transition: color var(--tnormal) var(--ease),
                background var(--tnormal) var(--ease);
  }

  .text a:hover,
  .text a:focus {
    text-decoration: none;
    outline: none;
    color: white;
    background: var(--color);
  }

  .plus {
    padding: 0 0.2rem;
    user-select: none
  }

  .in-brackets:before {
    content: '(';
  }
  .in-brackets:after {
    content: ')';
  }

  .highlight > * {
    border-radius: 1em;
    background: var(--color-01);
  }

  .highlight > a:hover {
    color: var(--color);
    background: var(--award-color);
  }

  /* 640px */
  @media screen and (min-width: 40em) {
    h4 {
      line-height: normal;
      margin: 0;
    }

    @supports (display: grid) {
      .wrapper {
        grid-template-columns: 6rem auto;
      }
    }

    .thumbnail:before {
      padding-top: 100%;
    }
  }
</style>

<ol class="software-tools" class:ie11>
  {#each software as tool}
    <li id={tool.id}>
      <div class="wrapper">
        <div>
          <div class="thumbnail">
            <Background
              img="/assets/images/thumbnail-{tool.id}"
              size="contain"
              webp
            />
          </div>
        </div>
        <div class="body">
          <h4>{tool.title}</h4>
          <p>{tool.description}</p>
          <div class="text smaller">
            <span class="flex dot-after">{#each tool.languages as language, i}<SoftwareIcon language={language} />{language}{#if i < tool.languages.length - 1}<span class="plus">+</span>{/if}{/each}
            </span>
            {#if tool.frameworks}
            <span class="flex in-brackets">
              Frameworks: {#each tool.frameworks as framework, i}<span><a href={framework.url} target="_blank" rel="noopener noreferrer">{framework.label}</a>{#if i < tool.frameworks.length - 1}<span class="plus">+</span>{/if}</span>{/each}
            </span>
            {/if}
          </div>
          <LinkList>
            <li>
              <a href={tool.code} target="_blank" rel="noopener noreferrer">
                <svg>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="assets/images/icons.svg#code" />
                </svg>
                <span class="text">Code</span>
              </a>
            </li>
            {#if tool.url}
              <li>
                <a href={tool.url} target="_blank" rel="noopener noreferrer">
                  <svg>
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="assets/images/icons.svg#globe" />
                  </svg>
                  <span class="text">Project</span>
                </a>
              </li>
            {/if}
            {#if tool.talk}
              <li>
                <button on:click={showYoutube(tool.talk[0])}>
                  <svg>
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="assets/images/icons.svg#globe" />
                  </svg>
                  <span class="text">Talk</span>
                </button>
              </li>
            {/if}
            <li>
              <a
                href={`${tool.code}/stargazers`}
                target="_blank"
                rel="noopener noreferrer"
                title={`${tool.stars} stars on GitHub as of ${(new Date(tool.lastChecked)).toLocaleDateString()}`}
              >
                <svg>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/assets/images/icons.svg#star" />
                </svg>
                <span class="text">{tool.stars}</span>
              </a>
            </li>
            {#if tool.highlight}
              {#each tool.highlight as highlight}
              <li class="highlight">
                {#if highlight.url}
                  <a href={highlight.url} target="_blank" rel="noopener noreferrer">
                    <svg>
                      <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="/assets/images/icons.svg#trophy" />
                    </svg>
                    <span class="text">{highlight.label}</span>
                  </a>
                {:else}
                  <span>
                    <svg>
                      <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="/assets/images/icons.svg#trophy" />
                    </svg>
                    <span class="text">{highlight.label}</span>
                  </span>
                {/if}
              </li>
              {/each}
            {/if}
          </LinkList>
        </div>
      </div>
    </li>
  {/each}
</ol>
