<script>
  export let icon;
  export let title;
  export let href = null;
  export let external = false;
  export let onClick = null;
  export let rel = null;
</script>

<style>
  div {
    position: relative;
    height: 7.5rem;
    margin: 0 0.5rem 1rem 0.5rem;
    font-size: 0.8rem;
  }

  a, button, span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    color: var(--color);
    border-radius: 0.25rem;
    border: 0;
    background: rgba(185, 185, 185, 0.66);
    text-decoration: none;
    transition: color var(--tnormal) var(--ease),
                transform var(--tnormal) var(--ease),
                background var(--tnormal) var(--ease);
  }

  a, button {
    cursor: pointer;
  }

  a:hover, button:hover,
  a:active, button:active,
  a:focus, button:focus {
    outline: none;
    color: white;
    background-color: var(--color);
    transform: scale(1.05);
  }

  a:active, button:active {
    transform: scale(0.95);
  }

  svg {
    position: relative;
    width: 3rem;
    height: 3rem;
  }

  strong {
    padding-top: 0.5rem;
  }

  /* 640px */
  @media screen and (min-width: 22.5em) {
    div {
      font-size: 0.9rem;
    }
  }

  /* 640px */
  @media screen and (min-width: 40em) {
    div {
      height: 10rem;
      font-size: 1rem;
    }

    svg {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  /* 800px */
  @media screen and (min-width: 50em) {
    div {
      height: 10rem;
    }

    svg {
      width: 4rem;
      height: 4rem;
    }
  }
</style>

<div>
  {#if href}
    <a
      {href}
      target="{external ? '_blank' : ''}"
      rel="{external ? `${rel ? `${rel} ` : ''}noopener noreferrer` : ''}"
    >
      {#if icon}
        <svg>
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="/assets/images/icons.svg#{icon}" />
        </svg>
      {/if}
      <strong>{title}</strong>
    </a>
  {:else if onClick}
    <button on:click={onClick}>
      {#if icon}
        <svg>
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="/assets/images/icons.svg#{icon}" />
        </svg>
      {/if}
      <strong>{title}</strong>
    </button>
  {:else}
    <span>
      {#if icon}
        <svg>
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="/assets/images/icons.svg#{icon}" />
        </svg>
      {/if}
      <strong>{title}</strong>
    </span>
  {/if}
</div>
