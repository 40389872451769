<script>
  import Anchor from './Anchor.svelte';
  import CenterHeadline from './CenterHeadline.svelte';
  import FullWidthHeadline from './FullWidthHeadline.svelte';
  import NavBg from './NavBg.svelte';
  import Section from './Section.svelte';
  import Wrap from './Wrap.svelte';
  import VSpace from './VSpace.svelte';

  import { ie11 } from './utils.js';

  export let height;
  export let offsetTop;

  const background = '#66ccff';
</script>

<style>
  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  ol {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  li:first-child {
    margin-top: 0;
  }

  @supports (display: grid) {
    li {
      display: grid;
      grid-column-gap: 0.25rem;
      grid-row-gap: 0;
      grid-template-columns: repeat(1fr);
    }
  }

  a {
    margin: 0 -0.25rem;
    padding: 0 0.25rem;
    border-radius: 1em;
    text-decoration: underline solid var(--color-033);
    transition: color var(--tnormal) var(--ease),
                background var(--tnormal) var(--ease);
  }

  a:hover,
  a:focus {
    text-decoration: none;
    outline: none;
    color: white;
    background: var(--color);
  }

  .ie11 li .date {
    width: 10rem;
    margin-right: 1rem;
  }

  .ie11 li .body {
    min-width: 20rem;
    width: calc(100% - 11rem);
  }

  .body {
    position: relative;
  }

  .date {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  .location {
    display: flex;
    align-items: center;
    margin-top: 0.2rem;
    font-size: 0.8rem;
    color: #4b73be;
  }

  .location svg {
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.2rem;
  }

  .location span {
    line-height: 0.8rem;
  }

  .intro {
    margin-top: 0.5em;

    &:first-child {
      margin-top: 0;
    }
  }

  /* 640px */
  @media screen and (min-width: 40em) {
    .intro {
      font-size: 1.25em;
    }

    @supports (display: grid) {
      li {
        grid-template-columns: 10rem auto;
      }
    }
  }
</style>

<Section
  id="bio"
  background={background}
  bind:height={height}
  bind:offsetTop={offsetTop}
>
  <NavBg background="rgba(102, 204, 255, 0.9)" />
  <Wrap>
    <FullWidthHeadline title="Some Bits About Me"/>
    <p class="intro">
      I'm the <em>Head of Visualization Research</em> at <a href="https://www.ozette.com" target="_blank" rel="noopener noreferrer">Ozette Technologies</a> – a biotech startup trying to digitize the Human immune system. I lead the research and development of scalable and intelligent data visualization and exploration tools for analyzing high-dimensional single-cell data to drive decision making and understanding.
    </p>
    <p class="intro">
      I received my <abbr title="Doctor of Philosophy">PhD</abbr> in computer science from <a href="https://seas.harvard.edu" target="_blank" rel="noopener noreferrer">Harvard University</a>, where I worked with <a href="https://en.wikipedia.org/wiki/Hanspeter_Pfister" target="_blank" rel="noopener noreferrer">Hanspeter Pfister</a> and <a href="https://hidivelab.org/" target="_blank" rel="noopener noreferrer">Nils Gehlenborg</a> on <a href="https://seas.harvard.edu/news/2018/11/grad-student-profile-fritz-lekschas" target="_blank" rel="noopener noreferrer">scalable visualization tools for pattern-driven exploration of epigenomic data</a>. Prior to my <abbr title="Doctor of Philosophy">PhD</abbr>, I was a visiting postgrad research fellow in the <a href="https://dbmi.hms.harvard.edu" target="_blank" rel="noopener noreferrer">Department of Biomedical Informatics</a> at <a href="https://hms.harvard.edu" target="_blank" rel="noopener noreferrer">Harvard Medical School</a>, where I conducted research with <a href="https://hidivelab.org/" target="_blank" rel="noopener noreferrer">Nils Gehlenborg</a> and <a href="https://compbio.hms.harvard.edu" target="_blank" rel="noopener noreferrer">Peter J Park</a>. I obtained a Bachelor and Master degree in bioinformatics from the <a href="https://www.fu-berlin.de/en/" target="_blank" rel="noopener noreferrer">Freie Universität Berlin</a> in Germany.
    </p>
    <p class="intro">
      My research has been recognized with <a href="#awards-honors">several prestigious awards</a>, including the <a href="https://www.eg.org/wp/eurographics-awards-programme/eurovis-phd-award/" target="_blank" rel="noopener noreferrer">EuroVis Best PhD Award</a>, an IEEE VGTC Visualization Dissertation Award Honorable Mention, a <a href="https://www.siebelscholars.com/scholar-profile/1916/" target="_blank" rel="noopener noreferrer">Siebel Scholarship</a>, the Best Paper Award from EuroVis 2020, and a Best Paper Honorable Mention from IEEE InfoVis 2020.
    </p>

    <CenterHeadline id="education" title="Education"/>
    <ol class:ie11>
      <li>
        <div class="date">
          <time datetime="2016-09-01">09.2016</time>
          &ndash;
          <time datetime="2021-27-05">05.2021</time>
        </div>
        <div class="body">
          <h4>Doctor of Philosophy in Computer Science</h4>
          <p>
            <em>Harvard University</em>, Cambridge, MA, United States.
          </p>
        </div>
      </li>
      <li>
        <div class="date">
          <time datetime="2012-10-01">10.2012</time>
          &ndash;
          <time datetime="2016-04-01">04.2016</time>
        </div>
        <div class="body">
          <h4>Master of Science in Bioinformatics</h4>
          <p>
            <em>Freie Universität Berlin</em> and
            <em>Charité &ndash; Universitätsmedizin Berlin</em>, Berlin,
            Germany.
          </p>
        </div>
      </li>
      <li>
        <div class="date">
          <time datetime="2009-10-01">10.2009</time>
          &ndash;
          <time datetime="2012-12-01">12.2012</time>
        </div>
        <div class="body">
          <h4>Bachelor of Science in Bioinformatics</h4>
          <p>
            <em>Freie Universität Berlin</em> and
            <em>Charité &ndash; Universitätsmedizin Berlin</em>, Berlin,
            Germany.
          </p>
        </div>
      </li>
    </ol>

    <CenterHeadline id="work-experience" title="Work Experience"/>
    <ol class:ie11>
      <li>
        <div class="date">
          <time datetime="2021-06-15">06.2021</time>
          &ndash;
          Present
        </div>
        <div class="body">
          <h4>Ozette Technologies</h4>
          <p>
            Head of Visualization Research (07.2022 &ndash; Present)<br/>
            Research Scientist (06.2021 &ndash; 07.2022)
          </p>
          <div class="location">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/assets/images/icons.svg#location" />
            </svg>
            <span>Cambridge, MA, United States</span>
          </div>
        </div>
      </li>
      <li>
        <div class="date">
          <time datetime="2017-06-01">06.2017</time>
          &ndash;
          <time datetime="2021-27-05">05.2021</time>
        </div>
        <div class="body">
          <h4>Harvard School of Engineering and Applied Sciences</h4>
          <p>Research Assistant</p>
          <div class="location">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/assets/images/icons.svg#location" />
            </svg>
            <span>Cambridge, MA, United States</span>
          </div>
        </div>
      </li>
      <li>
        <div class="date">
          <time datetime="2018-06-01">06.2018</time>
          &ndash;
          <time datetime="2018-08-01">08.2018</time>
        </div>
        <div class="body">
          <h4>Novartis Institutes for BioMedical Research</h4>
          <p>Research Intern</p>
          <div class="location">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/assets/images/icons.svg#location" />
            </svg>
            <span>Cambridge, MA, United States</span>
          </div>
        </div>
      </li>
      <li>
        <div class="date">05.2015 &ndash; 05.2016</div>
        <div class="body">
          <h4>Harvard Medical School</h4>
          <p>Visiting Postgrad Research Fellow in Biomedical Informatics</p>
          <div class="location">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/assets/images/icons.svg#location" />
            </svg>
            <span>Boston, MA, United States</span>
          </div>
        </div>
      </li>
      <li>
        <div class="date">
          <time datetime="2010-10-01">10.2010</time>
          &ndash;
          <time datetime="2016-01-01">01.2016</time>
        </div>
        <div class="body">
          <h4>Berlin-Brandenburg Center for Regenerative Therapies, Charité &ndash; Universitätsmedizin Berlin</h4>
          <p>Research Assistant</p>
          <div class="location">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/assets/images/icons.svg#location" />
            </svg>
            <span>Berlin, Germany</span>
          </div>
        </div>
      </li>
      <li>
        <div class="date">
          <time datetime="2014-08-01">08.2014</time>
          &ndash;
          <time datetime="2014-10-01">10.2014</time>
        </div>
        <div class="body">
          <h4>European Bioinformatics Institute</h4>
          <p>Trainee</p>
          <div class="location">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/assets/images/icons.svg#location" />
            </svg>
            <span>Cambridge, UK</span>
          </div>
        </div>
      </li>
      <li>
        <div class="date">
          <time datetime="2014-02-01">02.2014</time>
          &ndash;
          <time datetime="2014-05-01">05.2014</time>
        </div>
        <div class="body">
          <h4>Center for iPS Cell Research and Application, Kyoto University</h4>
          <p>Visiting Research Assistant</p>
          <div class="location">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/assets/images/icons.svg#location" />
            </svg>
            <span>Kyōto, Japan</span>
          </div>
        </div>
      </li>
      <li>
        <div class="date">
          <time datetime="2013-08-01">08.2013</time>
          &ndash;
          <time datetime="2014-02-01">02.2014</time>
        </div>
        <div class="body">
          <h4>Shin Nippon Biomedical Laboratories &amp; Medipolis Proton Therapy and Research Center</h4>
          <p>Intern</p>
          <div class="location">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/assets/images/icons.svg#location" />
            </svg>
            <span>Kagoshima, Japan</span>
          </div>
        </div>
      </li>
      <li>
        <div class="date">
          <time datetime="2012-05-01">05.2012</time>
          &ndash;
          <time datetime="2012-08-01">08.2012</time>
        </div>
        <div class="body">
          <h4>Seoul National University</h4>
          <p>Visiting Research Assistant</p>
          <div class="location">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/assets/images/icons.svg#location" />
            </svg>
            <span>Seoul, South Korea</span>
          </div>
        </div>
      </li>
      <li>
        <div class="date">
          <time datetime="2009-02-01">02.2009</time>
          &ndash;
          <time datetime="2009-04-01">04.2009</time>
        </div>
        <div class="body">
          <h4>Robert Koch Institute</h4>
          <p>Intern</p>
          <div class="location">
            <svg>
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/assets/images/icons.svg#location" />
            </svg>
            <span>Berlin, Germany</span>
          </div>
        </div>
      </li>
    </ol>

    <CenterHeadline id="awards-honors" title="Awards &amp; Honors"/>
    <ol class:ie11>
      <li>
        <div class="date"><time>2022</time></div>
        <div class="body">
          <Anchor id="vgtc-visualization-dissertation-award-honorable-mention" abs />
          <h4>IEEE VGTC Visualization Dissertation Award Honorable Mention</h4>
          <p>
            My dissertation "Scalable Visualization Tools for Pattern-Driven Data Exploration" received a <em>Visualization Dissertation Award Honorable Mention</em> from the
            <a
              href="https://virtual.ieeevis.org/year/2022/awards.html"
              target="_blank" rel="noopener noreferrer"
            >
              IEEE Visualization and Graphics Technical Community
            </a>.
          </p>
        </div>
      </li>
      <li>
        <div class="date" />
        <div class="body">
          <Anchor id="eurovis-best-phd-award" abs />
          <h4>EuroVis Best PhD Award</h4>
          <p>
            My dissertation "Scalable Visualization Tools for Pattern-Driven Data Exploration" received the <em>Best PhD Award</em> from the
            <a
              href="https://www.eg.org/wp/eurographics-awards-programme/eurovis-phd-award/"
              target="_blank" rel="noopener noreferrer"
            >
              EuroVis Conference
            </a>.
          </p>
        </div>
      </li>
      <li>
        <div class="date"><time>2021</time></div>
        <div class="body">
          <h4>BioVis Best Abstract Award</h4>
          <p>
            Our poster abstract "Grammar-Based Interactive Visualization of Genomics Data" received the <em>Best Abstract Award</em> at the
            <a
              href="https://twitter.com/ngehlenborg/status/1420050208776343554"
              target="_blank" rel="noopener noreferrer"
            >
              BioVis Symposium
            </a>
            at ISMB 2021.
          </p>
        </div>
      </li>
      <li>
        <div class="date" />
        <div class="body">
          <Anchor id="harvard-cs-outstanding-phd-dissertation-honorable-mention" abs />
          <h4>Harvard CS Outstanding PhD Dissertation Honorable Mention</h4>
          <p>
            My dissertation "Scalable Visualization Tools for Pattern-Driven Data Exploration" received an <em>Outstanding PhD Dissertation Honorable Mention</em> from
            <a
              href="https://www.seas.harvard.edu/computer-science"
              target="_blank" rel="noopener noreferrer"
            >Computer Science at Harvard</a>.
            Third best CS dissertation in the 2020-21 academic year.
          </p>
        </div>
      </li>
      <li>
        <div class="date"><time>2020</time></div>
        <div class="body">
          <h4>IEEE InfoVis Best Paper Honorable Mention</h4>
          <p>
            Our paper "A Generic Framework and Library for Exploration of Small Multiples through Interactive Piling" received a <em>Best Paper Honorable Mention</em> at the
            <a
              href="http://ieeevis.org/year/2020/info/awards/best-paper-awards#infovis"
              target="_blank" rel="noopener noreferrer"
            >IEEE Visualization Conference</a> (InfoVis).
            Among the best 5 papers out of 250 submissions.
          </p>
        </div>
      </li>
      <li>
        <div class="date" />
        <div class="body">
          <h4>Siebel Scholar, Class of 2021</h4>
          <p>
            <em>1-year scholarship</em> of $35,000 for the final year of graduate studies from
            <a
              href="https://www.siebelscholars.com/scholar-profile/1916/"
              target="_blank" rel="noopener noreferrer"
            >Siebel Scholars</a>. The program recognizes the most talented students at the world’s leading graduate schools of business, computer science, bioengineering, and energy science.
          </p>
        </div>
      </li>
      <li>
        <div class="date" />
        <div class="body">
          <h4>EuroVis Best Paper Award</h4>
          <p>
            Our paper "Peax: Interactive Visual Pattern Search in Sequential Data Using Unsupervised Deep Representation Learning" received the <em>Best Paper Award</em> at the
            <a
              href="https://youtu.be/oQG5DxqiXPI?t=138"
              target="_blank" rel="noopener noreferrer"
            >EuroVis conference</a>.
            Best paper out of 168 submissions.
          </p>
        </div>
      </li>
      <li>
        <div class="date"><time>2018</time></div>
        <div class="body">
          <h4>BioVis Best Poster Award</h4>
          <p>
            Our poster on "Feature-Centric Visual Exploration
            of Genome Interaction Maps" received the <em>Best Poster Award</em> at the
            <a
              href="http://biovis.net/2018/ismb/2018/07/09/best-poster.html"
              target="_blank" rel="noopener noreferrer"
            >
              BioVis symposium
            </a>
            at ISMB 2018 in Chicago.
          </p>
        </div>
      </li>
      <li>
        <div class="date"><time>2017</time></div>
        <div class="body">
          <Anchor id="award-ilw-best-master-thesis" abs />
          <h4>ILW Best Master Thesis Award</h4>
          <p>
            My thesis "Ontology-guided exploration of biomedical data repositories" received the <em>Best Master Thesis</em> award in the informatics for life sciences (ILW) category from the
            <a href="https://gi.de/" target="_blank">German Informatics Society (GI)</a>
            and
            <a href="https://www.gmds.de/" target="_blank">German Association for Medical Informatics, Biometry and Epidemiology (GMDS)</a>.
          </p>
        </div>
      </li>
      <li>
        <div class="date"><time>2016</time></div>
        <div class="body">
          <h4>Harvard University</h4>
          <p>
            <em>6-year PhD fellowship</em> from Harvard University.
          </p>
        </div>
      </li>
      <li>
        <div class="date"><time>2015</time></div>
        <div class="body">
          <h4>DAAD PROMOS</h4>
          <p>
            <em>6-month scholarship</em> from Freie Universität Berlin and the
            <a href="https://www.daad.de/en/" target="_blank">German Academic Exchange Program (DAAD)</a>.
          </p>
        </div>
      </li>
      <li>
        <div class="date"><time>2014</time></div>
        <div class="body">
          <h4>20<sup>th</sup> Heinz Nixdorf Asia-Pacific Leadership Program</h4>
          <p>
            <em>10-month scholarship</em> comprised of a 6-month industrial internship and four months of full-time language courses in Japan to experience Japanese business and corporate culture.
          </p>
        </div>
      </li>
    </ol>
    <VSpace height="4.5"/>
  </Wrap>
</Section>
