<script>
  import CenterHeadline from './CenterHeadline.svelte';
  import FullWidthHeadline from './FullWidthHeadline.svelte';
  import SoftwareList from './SoftwareList.svelte';
  import NavBg from './NavBg.svelte';
  import Section from './Section.svelte';
  import Wrap from './Wrap.svelte';
  import VSpace from './VSpace.svelte';

  import software from './content/software.yaml';

  export let height;
  export let offsetTop;
  export let active = false;

  const background = '#FFD473';
</script>

<style>
  /* 640px */
  @media screen and (min-width: 40em) {
    .intro {
      font-size: 1.25em;
    }
  }
</style>

<Section
  id="software"
  background={background}
  bind:height={height}
  bind:offsetTop={offsetTop}
>
  <NavBg background="rgba(255, 212, 115, 0.9)" transition {active} />
  <Wrap>
    <FullWidthHeadline title="Open-Source Tools"/>
    <p class="intro">
      I'm interested in web-based software tools for visually exploring high-dimensional data and have (co-)created several popular open-source software projects. I primarily work in JavaScript/TypeScript and Python. These days I think a lot about frameworks for intelligent, composable, and scalable visualization software tools.
    </p>
    <CenterHeadline id="selected-software" title="Selected Software"/>
    <SoftwareList software={software} />
    <VSpace height="4.5"/>
  </Wrap>
</Section>
