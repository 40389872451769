<script>
  import Background from './Background.svelte';
  import Card from './Card.svelte';
  import FullWidthHeadline from './FullWidthHeadline.svelte';
  import Grid from './Grid.svelte';
  import NavBg from './NavBg.svelte';
  import Section from './Section.svelte';
  import Wrap from './Wrap.svelte';
  import VSpace from './VSpace.svelte';

  import { timeout } from './utils.js';

  export let height;
  export let offsetTop;
  export let active = false;

  const emailMe = async (e) => {
    e.preventDefault();
    await timeout(100);
    window.location = 'mailto:fritz@lekschas.de?subject=[Lekschas.de]';
  };

  const background = '#D9D9D9';
</script>

<Section id="contact" {background} bind:height bind:offsetTop>
  <Background img="/assets/images/map-gray" opacity="0.15" webp />
  <NavBg background="rgba(217, 217, 217, 0.9)" transition {active} />
  <Wrap>
    <FullWidthHeadline title="Get In Touch!" />
    <Grid>
      <Card
        title="LinkedIn"
        icon="linkmal"
        href="https://linkedin.com/in/flekschas/"
        external
      />
      <Card
        title="Bluesky"
        icon="himmel"
        href="https://bsky.app/profile/lekschas.de"
        external
      />
      <Card title="E-Mail" icon="mail" onClick={emailMe} />
      <Card
        title="GitHub"
        icon="codewurst"
        href="https://github.com/flekschas"
        external
      />
      <Card
        title="Google Scholar"
        icon="gscholar"
        href="https://scholar.google.com/citations?user=v1_FiEgAAAAJ"
        external
      />
      <Card
        title="Speaker Deck"
        icon="speakerdeck"
        href="https://speakerdeck.com/flekschas"
        external
      />
    </Grid>
    <VSpace height="2" />
  </Wrap>
</Section>
