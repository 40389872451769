<script>
  export let bigger = false;
  export let lessTopSpacing = false;
</script>

<style>
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    gap: 0.25rem 0.75rem;
  }

  ul :global(li) {
    list-style: none;
  }

  ul :global(li > span), ul :global(a), ul :global(button) {
    display: flex;
    align-items: center;
    margin: 0.1rem -0.15rem;
    padding: 0.15rem 0.3rem;
  }

  ul :global(a), ul :global(button) {
    cursor: pointer;
    text-decoration: none;
    border: 0;
    border-radius: 1em;
    background: var(--color-0);
    transition: color var(--tnormal) var(--ease),
                background var(--tnormal) var(--ease),
                transform var(--tnormal) var(--ease);
  }

  ul :global(a:hover), ul :global(button:hover),
  ul :global(a:active), ul :global(button:active),
  ul :global(a:focus), ul :global(button:focus) {
    outline: none;
    color: white;
    background: var(--color);
    transform: scale(1.05);
  }

  ul :global(a:active), ul :global(button:active) {
    transform: scale(0.95);
  }

  ul :global(svg) {
    width: 0.9rem;
    height: 0.9rem;
  }

  ul :global(.text) {
    margin-left: 0.2rem;
    line-height: 1em;
    height: 0.7rem;
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  .bigger :global(.text) {
    height: 1rem;
    font-size: 0.9rem;
    line-height: 1rem;
    text-transform: none;
  }

  .bigger :global(svg) {
    width: 1rem;
    height: 1rem;
  }

  .lessTopSpacing {
    margin-top: 0.25rem;
  }

  /* 640px */
  @media screen and (min-width: 40em) {
    .bigger :global(.text) {
      height: 1.1rem;
      font-size: 1rem;
      line-height: 1.1rem;
    }

    .bigger :global(svg) {
      width: 1.1rem;
      height: 1.1rem;
    }

    ul :global(li > span), ul :global(a), ul :global(button) {
      margin: -0.05rem -0.2rem;
      padding: 0.15rem 0.3rem;
    }
  }
</style>

<ul class:bigger class:lessTopSpacing>
  <slot />
</ul>
