<script>
  export let language;

  const languageToIcon = {
    javascript: 'javascript',
    python: 'python',
    sql: 'data',
  }

  $: lang = language.toLowerCase();
  $: icon = lang in languageToIcon ? languageToIcon[lang] : undefined;
</script>

<style>
  svg {
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.125rem;
  }
</style>

{#if icon}
  <svg>
    <use
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xlink:href={`/assets/images/icons.svg#${icon}`}
    />
  </svg>
{/if}
